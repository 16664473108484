.featuredProducts {
  width: 100%;
  align-items: center;
  // border-style: dotted;
}
.viewProductsButton {
  position: relative;
  top: 50%;
  left: 43%;
  background-color: #805b5b; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 16px;
  border-radius: 8px;
}
