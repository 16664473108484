// * {box-sizing:border-box}

.carrouselMainStyle {
  min-width: 220px;
}

.styleCarrouselImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}

.styleCarrouselDot {
  text-align: center;
}

.styleCarrousel {
  display: flex;
  min-height: 380px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

}
.button {

  
  background-color: #805b5b;
  border: none;
  color: white;

  text-align: center;
  text-decoration: none;

  font-size: 16px;
  border-radius: 8px;
}

.prev:hover,
.next:hover {
  background-color: rgba(114, 114, 114, 0.8);
}
.divtext {
display: flex;
flex-direction: column;
min-height: 100px;
align-items: center;
}
// /* Caption text */
.text {
  color: #805b5b;
  font-size: 15px;
  width: 20vh;
  word-wrap: break-word;
  text-align: center;
}

.divbutton {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

// /* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}
