.flexDiv{
  text-align: center;
  align-items: stretch;

} 
.sidePanel
{
    display: flex;
    flex-direction: row;
    align-items: stretch; 
}

