.textHeader {

    
  color: #805b5b;

  text-align: center;
  text-decoration: none;

  font-size: 16px;
  border-radius: 8px;
  word-wrap: break-word;
}
.search {
  position: relative;
  top: 50%;
  
  right: 0%;
 // background-color: #805b5b; /* Green */
  border: 2px;
  color:#805b5b;;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
}
.table {
  width: 100%;
}
.header
{

  display: flex ;
  text-align: center;
  width: 100%;
  max-width: 100vw;
  flex-wrap: wrap;
}

.shoppingcart{
  max-width:50px;
  max-height:50px;

}

.logo{
  max-width:100px;
  max-height:100px;

}


.divwithborder
{
  margin: auto;
  max-width: 25%;
  min-width: 160px;
 // border: 3px solid green;
 // padding: 10px;


}