.forminputs
{
    display: flex;
    flex-direction: column;
}
.input
{
    width: 30vw;
    display: flex;
    flex-direction: column;
    align-items: stretch;


}