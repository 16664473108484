.galery
{
    margin: auto;
 max-width: 40vw;
 text-align: center;
}

.galerydetails
{
    margin: auto;
 max-width: 80vw;

 text-align:justify;
}